import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Banner from '../../../core/Banner';
import Panel from '../../../core/Panel';
import Heading from '../../../core/Heading';
import Columns from '../../../core/Columns';
import Column from '../../../core/Column';
import FeatureList from '../../../core/FeatureList';
import Image from '../../../core/Image';
import Paragraph from '../../../core/Paragraph';
import Icon from '../../../core/Icon';

import './index.css';

import guestExperienceImage from './images/check_availability@2x-100.jpg';
import increaseRevenueImage from './images/upsell.jpg';
import addRoomImage from './images/rates.jpg';
import supplyAndDemandImage from './images/supply-demand.svg';
import largePhotosIconImage from './images/glyph_zoom+white.svg';
import mobileReadyIconImage from './images/glyph_phone+white.svg';
import analyticsIconImage from './images/glyph_chart+white.svg';
import automateReservationImage from './images/reservation_automation_composite.svg';

export class BookingEnginePage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component booking-engine-page">
          <Helmet title="Booking Engine – ThinkReservations">
            <meta
              name="description"
              content="Increase reservations, upsell packages, and support promotions with the hotel booking engine from ThinkReservations. Learn more here."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Service',
                serviceType: 'Booking Engine and Solutions',
                category:
                  'Reservation Management Software for Small and Boutique Hotels',
                provider: {
                  '@type': 'Organization',
                  name: 'ThinkReservations',
                },
                areaServed: {
                  '@type': 'Country',
                  name: 'USA',
                },
              })}
            />
          </Helmet>

          <Banner
            header="Booking Engine"
            subheader="Sell more reservations, items, and packages"
            content="Our hotel booking engine is designed to increase conversions by providing a better customer booking experience. Businesses report receiving more online reservations and selling more items and packages once they upgrade to ThinkReservations."
          />

          <Panel
            id="panel-enhance-the-guest-experience"
            backgroundColor="#f5f3f3"
          >
            <Heading size="large" level={2}>
              Enhance the guest experience
            </Heading>
            <Columns>
              <Column>
                <Image src={guestExperienceImage} />
              </Column>
              <Column>
                <FeatureList>
                  <li>Simple for your guests</li>
                  <li>Customized to look like your website</li>
                  <li>Book by room or room type</li>
                  <li>More direct bookings!</li>
                </FeatureList>
              </Column>
            </Columns>
          </Panel>

          <Panel id="panel-increase-revenue">
            <Columns>
              <Column>
                <Heading size="large" level={2}>
                  Increase revenue by upselling items and packages
                </Heading>
                <Paragraph>
                  Offer your guests the benefit of upgrading their stay before
                  they complete the reservation through our hotel booking engine
                  software. Curated items, packages, and services let guests
                  book the perfect getaway. Even better, we make it simple to
                  connect with your current payment processing system, so
                  managing transactions is a snap.
                </Paragraph>
              </Column>
              <Column>
                <Image src={increaseRevenueImage} border={true} />
              </Column>
            </Columns>
          </Panel>

          <Panel id="panel-support-rates" backgroundColor="#f5f3f3">
            <Columns>
              <Column>
                <Image src={addRoomImage} border={true} />
              </Column>
              <Column>
                <Heading size="large" level={2}>
                  We support specials, promotions, and discounts
                </Heading>
                <Paragraph>
                  Our hospitality booking engine makes it simple to offer
                  multiple rates at once. Enable guests to choose between
                  standard rates, discounts, packages, and varied cancellation
                  policies.
                </Paragraph>
              </Column>
            </Columns>
          </Panel>

          <Panel id="panel-dynamic-pricing" backgroundColor="#575451">
            <Heading size="large" level={2}>
              Increase revenue with dynamic pricing
            </Heading>
            <Columns>
              <Column id="panel-dynamic-pricing-column-1" colSpan={3.75}>
                <div id="panel-dynamic-pricing-column-1-inner-wrapper">
                  <Paragraph className="automatically-change">
                    Automatically change the price of rooms based on supply and
                    demand.
                  </Paragraph>
                  <FeatureList bulletColor="white">
                    <li>
                      <div className="title">Respond to demand</div>
                      <div className="description">
                        Raise your rates when your occupancy increases
                      </div>
                    </li>
                    <li>
                      <div className="title">Real-time</div>
                      <div className="description">
                        Prices change in real time across all your channels
                      </div>
                    </li>
                    <li>
                      <div className="title">Measurable results</div>
                      <div className="description">
                        Our customers report a higher Average Daily Rate in
                        their peak season
                      </div>
                    </li>
                  </FeatureList>
                </div>
              </Column>
              <Column colSpan={6.25}>
                <Image src={supplyAndDemandImage} />
              </Column>
            </Columns>
          </Panel>

          <Panel id="booking-engine-features" backgroundColor="#f5f3f3">
            <Columns verticalAlign="top">
              <Column>
                <Icon
                  title="Large photos"
                  level={3}
                  image={largePhotosIconImage}
                  imageWidth={102}
                  imageHeight={102}
                  description="We make it easy for guests to enlarge your images and experience the room virtually"
                />
              </Column>
              <Column>
                <Icon
                  title="Mobile ready"
                  image={mobileReadyIconImage}
                  imageWidth={57.6}
                  imageHeight={96}
                  description="Let your guests book with you even on the go with our mobile-ready reservation booking system"
                />
              </Column>
              <Column>
                <Icon
                  title="Analytics"
                  image={analyticsIconImage}
                  imageWidth={128}
                  imageHeight={96}
                  description="Integrations with systems like Google Analytics allow you to track the effectiveness of your marketing campaigns"
                />
              </Column>
            </Columns>
          </Panel>

          <Panel id="panel-automate-reservations" backgroundColor="#f27a44">
            <Heading size="xlarge" level={2} style={{ marginBottom: 0 }}>
              Automate your reservations
            </Heading>
            <Paragraph style={{ marginBottom: '54px' }}>
              When a reservation is created on the booking engine, we automate
              all the followup tasks so that you don't have to! Save time and
              energy with our convenient booking system
            </Paragraph>
            <Image src={automateReservationImage} />
          </Panel>
        </div>
      </App>
    );
  }
}

export default BookingEnginePage;
